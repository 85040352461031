<template>
  <transition name="slide">
    <CCard>
      <CCardBody>
        <div v-if="isLoadingRequired == false">
          <CButton color="primary" @click="create()">
            <CIcon name="cil-plus" /> Create New Experience
          </CButton>
          <br />
          <br />
          <br />
          <CAlert v-if="message" :color="alertType"> {{ message }}</CAlert>
          <CRow>
            <CCol sm="3">
              <label style="width: 95%">Category </label>
              <div class="form-group">
                <div class="input-group">
                  <treeselect v-model="selectedCategoryId" :defaultExpandLevel="Infinity" :isDefaultExpanded="true"
                    :multiple="false" :options="categories" />
                </div>
              </div>
            </CCol>

            <CCol sm="0">
              <CButton color="info" @click="selectedCategoryId ? getListFiltered() : getList()" class="btn" style="margin-top: 28px; margin-right: 1vw">
                <CIcon name="cil-list" />
                Filter
              </CButton>
            </CCol>
            <CCol sm="0">
              <CButton color="info" @click="selectedCategoryId = null; getList(true);" class="btn" style="margin-top: 28px">
                <CIcon name="cilXCircle" />
                Reset filters
              </CButton>
            </CCol>
          </CRow>
          <CDataTable :items="items" :fields="fields" :items-per-page="50" :clickableRows="true" @row-clicked="onRowClick" hover pagination>
            <template #status="{ item }">
              <td>
                <CBadge :color="getBadge(item.active)">
                  {{ item.active == true ? "Active" : "Inactive" }}
                </CBadge>
              </td>
            </template>
            <template #sortOrder="{ item }">
              <td>
                {{ item.sortOrder ?? "" }}
              </td>
            </template>
            <template #lastModified="{ item }">
              <td>
                {{ item.lastModified ? moment(new Date(item.lastModified)).format($dateFormat) : "" }}
              </td>
            </template>
            <template #ACTIONS="{ item }">
              <td style="width: 400px;">
                <CButton style="margin-left:10px" color="primary" @click.stop="edit(item.id)">
                  <CIcon name="cil-pencil" /> Edit
                </CButton>
                <CButton style="margin-left:10px" color="primary"
                  @click.stop=" launchPreview(item.experienceCode, item.nameTranslations[0].name)">
                  <CIcon name="cilExternalLink" /> Preview
                </CButton>
                <CButton style="margin-left:10px" color="success" @click.stop="clone(item.id)">
                  <CIcon name="cil-libraryAdd" /> Clone
                </CButton>
                <CButton color="danger" style="margin-left:10px" @click.stop="
                  warningModal = true;
                experience.id = item.id;
                experience.thumbnailUrl = item.thumbnailUrl;
                experience.experienceCode = item.experienceCode;
                experience.saleFrom = new Date(item.saleFrom).toISOString().split('T')[0];
                experience.saleTo = new Date(item.saleTo).toISOString().split('T')[0];
                ">
                  <CIcon name="cil-trash" /> Delete
                </CButton>
              </td>
            </template>
          </CDataTable>
          <CModal v-model="experience.id" title="Are you sure to delete selected experince ?" color="danger"
            :show.sync="warningModal" @update:show="closeModal">
            <CRow>
              <CCol col="6">
                <CInput label="Code" v-model="experience.experienceCode" disabled />
              </CCol>
              <CCol col="6">
                <CInput label="Sale From" v-model="experience.saleFrom" disabled />
              </CCol>
              <CCol col="6">
                <CInput label="Sale To" v-model="experience.saleTo" disabled />
              </CCol>
              <CCol col="6">
                <div><label>Thumbnail</label></div>
                <img :src="experience.thumbnailUrl" class="img-thumbnail" style="max-height: 200px" />
              </CCol>
            </CRow>
          </CModal>
        </div>
        <CSpinner style="display: block; position: fixed; top: 50%; left: 50%" v-if="isLoadingRequired === true"
          color="primary" />

      </CCardBody>
    </CCard>
  </transition>
</template>

<script>
import axios from "axios";
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
export default {
  name: "Experience",

  components: {
    Treeselect,
  },
  data: () => {
    return {
      token: localStorage.getItem("api_token"),
      partnerId: localStorage.getItem("partner_id"),
      emptyGuid: "00000000-0000-0000-0000-000000000000",
      selectedCategoryId: null,
      isLoadingRequired: false,
      //List
      items: [],
      categories: [],
      fields: ["status", "experienceName", "experienceCode", "sortOrder", "lastModified", "ACTIONS"],

      //Delete
      experience: {
        id: null,
        thumbnailUrl: null,
        code: null,
        saleFrom: null,
        saleTo: null
      },
      warningModal: false,

      //Alert
      message: null,
      alertType: "danger",
    };
  },
  computed: {},
  methods: {
    closeModal(status, evt, accept) {
      if (accept) {
        this.delete(this.experience.id);
      }
    },
    getListFiltered() {
      let self = this;
      localStorage.setItem("experienceCategoryFilter", self.selectedCategoryId);
      axios.get(`${this.$apiAdress}/v1/Experience/get-filtered?categoryId=` + self.selectedCategoryId)
        .then(function (response) {
          self.items = response.data;
        })
        .catch(function (error) {
                    self.alertType = "danger";
          setTimeout(function () {
              self.message = null;
          }, 10000);
          self.message = error;
        });
    },
    loadCategoryHierarchy(categories, pId) {
      return categories
        .filter((c) => c.parentId === pId)
        .map((c) => {
          var subCategories = this.loadCategoryHierarchy(c.subCategories, c.id);
          return {
            id: c.id,
            label: c.names[0].name,
            children: subCategories.length > 0 ? subCategories : undefined,
          };
        });
    },
    onRowClick(experience){
      this.edit(experience.id);
    },
    getCategories() {
      return new Promise((resolve, reject) => {
        let self = this;
        axios
          .get(`${this.$apiAdress}/v1/category/CategoryHierarchy`)
          .then(function (response) {
            self.categories = [{
            id: "00000000-0000-0000-0000-000000000000",
            label: "Main Category",
            children: self.loadCategoryHierarchy(response.data, null),
          }];
            resolve();
          })
          .catch(function (error) {
                      self.alertType = "danger";
          setTimeout(function () {
              self.message = null;
          }, 10000);
            self.message = error;
            reject();
          });
      });
    },
    getBadge(status) {
      switch (status) {
        case true:
          return "success";
        case false:
          return "secondary";
        default:
          return "primary";
      }
    },
    launchPreview(experienceCode, nameTranslation) {
      window.swWidgetApp.$router.push({ 
        name: 'BookingModalView', 
        query: { 
          expcode: experienceCode,
          expname: encodeURIComponent(nameTranslation),
          partnerid: this.partnerId,
          widgetsrc: this.$widgetSrc
        } 
      });
    },
    edit(id) {
      const link = `experiences/create/${id.toString()}`;
      this.$router.push({ path: link });
    },
    clone(id) {
      let self = this;
      self.isLoadingRequired = true;
      axios
        .get(`${this.$apiAdress}/v1/Experience/clone/${id}`
        )
        .then(function () {
                    self.alertType = "success";
          setTimeout(function () {
              self.message = null;
          }, 5000);
          self.message = "Successfully cloned experience.";
          self.getList();
          self.isLoadingRequired = false;
        })
        .catch(function (error) {
                    self.alertType = "danger";
          setTimeout(function () {
              self.message = null;
          }, 10000);
          self.message = error;
          self.isLoadingRequired = false;
        });
    },
    delete(id) {
      let self = this;
      axios
        .delete(`${this.$apiAdress}/v1/Experience/${id}`
        )

        .then(function () {
                    self.alertType = "success";
          setTimeout(function () {
              self.message = null;
          }, 5000);
          self.message = "Successfully deleted experience.";
          self.getList();
        })
        .catch(function (error) {
                    self.alertType = "danger";
          setTimeout(function () {
              self.message = null;
          }, 10000);
          self.message = error;
        });
    },
    create() {
      this.$router.push({ path: `experiences/create/${this.emptyGuid}` });
    },

    getList(resetFilters = false) {
      let self = this;
      if(resetFilters)
        localStorage.removeItem("experienceCategoryFilter");
      axios.get(`${this.$apiAdress}/v1/Experience`)
        .then(function (response) {
          self.items = response.data;
        })
        .catch(function (error) {
                    self.alertType = "danger";
          setTimeout(function () {
              self.message = null;
          }, 10000);
          self.message = error;
        });
    },
  },
  mounted: function () {
    const savedCategoryFilter = localStorage.getItem("experienceCategoryFilter");
    if(this.$route.query.categoryId){
      this.selectedCategoryId = this.$route.query.categoryId;
      localStorage.setItem("experienceCategoryFilter", this.selectedCategoryId);
      this.getListFiltered();
    }
    else if(savedCategoryFilter){
      this.selectedCategoryId = savedCategoryFilter;
      this.getListFiltered();
    }
    else{
      this.getList();
    }
    
    this.getCategories();
  },
};
</script>

<style scoped>
.card-body>>>table>tbody>tr>td {
  cursor: pointer;
}
</style>
